import type { SerializedStyles, Theme } from "@emotion/react";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import type { HTMLProps, ReactNode } from "react";

import { FontAwesomeIcon } from "@shared/react-fontawesome";

import * as styles from "./DeprecatedRadio.styles";

interface Props extends HTMLProps<HTMLInputElement> {
  /**
   * Adds custom styles to the label text styling
   */
  labelStyle?: (SerializedStyles | ((theme: Theme) => SerializedStyles))[] | SerializedStyles;
  /**
   * Content to render alongside the radio button
   *
   * @default undefined
   */
  children?: ReactNode;
}

const DeprecatedRadio = ({
  id,
  labelStyle,
  hidden,
  disabled,
  children,
  checked,
  ...rest
}: Props) => {
  const labelStyles = () => {
    return [styles.radioWrapper, hidden && styles.hidden, disabled && styles.disabled, labelStyle];
  };

  const iconContainerStyles = () => {
    return [styles.iconContainer.base, disabled && styles.iconContainer.disabled];
  };

  const iconStyles = () => {
    return [styles.icon.base, disabled && styles.icon.disabled];
  };

  return (
    <label css={labelStyles()}>
      <input
        id={id}
        type="radio"
        checked={checked}
        css={styles.radio}
        disabled={disabled}
        {...rest}
      />
      <div css={iconContainerStyles()}>
        <FontAwesomeIcon css={iconStyles()} icon={faCheck} />
      </div>
      <div css={styles.radioContent}>{children}</div>
    </label>
  );
};

export { DeprecatedRadio };
