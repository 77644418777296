import { css, type Theme } from "@emotion/react";

import { animations, dimensions, timing } from "@styles/index";

export const contentSection = {
  base: css`
    padding: 2rem;

    @media (max-width: ${dimensions.phoneLargeMax}) {
      padding: 1rem;
    }
  `,
  inlineContent: css`
    && {
      padding: 1rem 2rem;
    }
  `,
  heights: css`
    @media (min-width: ${dimensions.tablet}) {
      height: 16rem;
    }
    @media (min-width: ${dimensions.desktop}) {
      height: 18rem;
    }
  `,
  disabled: (theme: Theme) => css`
    * {
      color: ${theme.disabled.text};
    }
  `,
};

export const radioLabelOverride = css`
  align-items: center;
`;

export const labelWrapper = css`
  gap: 0;
  flex: 1;
  align-items: baseline;
`;

export const wrapper = {
  default: (theme: Theme) => css`
    background-color: ${theme.surface.interactiveBackground};
    transition:
      all ${timing.moderatelySlow} ${animations.easeOutCirc},
      visibility 0s;

    &:hover {
      border-color: ${theme.primary.borderHover};
    }

    > label {
      > div + div {
        margin-left: 0;
        margin-right: 2.5rem;
      }
      > input + div,
      > input + span {
        position: absolute;
        top: 1rem;
        right: 1rem;
        margin: 0;
      }

      @media (min-width: ${dimensions.tablet}) {
        > div + div {
          margin-right: 0;
        }
      }
    }
  `,

  checked: (theme: Theme) => css`
    border: 1px solid ${theme.primary.borderActive};
    background-color: ${theme.system.backgroundMuted};
  `,

  disabled: (theme: Theme) => css`
    border-color: ${theme.disabled.border};

    &:hover {
      border-color: ${theme.disabled.border};
    }
  `,

  checkbox: css`
    > label {
      position: relative;
    }
  `,
};

export const inlineLabel = {
  baseline: css`
    align-items: baseline;
  `,
  center: css`
    align-items: center;
  `,
};

export const inlineInput = css`
  > label > input + div {
    position: relative;
    top: initial;
    right: initial;
    left: 1rem;
    bottom: 0rem;
    margin-right: 0;
  }
  // Reduce width to match non-isInline Tiles
  > label > div + div {
    margin-right: -1rem;
    max-width: calc(100% - 1rem);
  }
`;

export const checkboxCentered = css`
  > label > input + div {
    @media (max-width: ${dimensions.phoneLargeMax}) {
      top: calc(50% - 0.5rem);
    }
  }
`;

export const fullHeight = css`
  height: 100%;
`;
